import React from "react";
import { Grid } from "@mui/material";

import config from "../../config";

function Slideone({ onNext, formState, setFormState }) {
  const handleNext = async () => {
    if (
      !formState.gallery_business_id ||
      !formState.gallery_agent.agent_name ||
      !formState.gallery_agent.agent_position ||
      !formState.gallery_agent.agent_gender ||
      !formState.gallery_agent.agent_birth ||
      !formState.gallery_agent.agent_phone.phone_prefix ||
      !formState.gallery_agent.agent_phone.phone_number
    ) {
      alert("All fields are required!");
      return;
    }

    onNext();
  };

  return (
    <div className="mobbackofslidethree">
      <div>
        <br />
        <div className="container">
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
              sm={12}
              style={{ textAlign: "center" }}
            >
              <br />
              <br />
              <h1 className="head_of_slidetwo">
                {" "}
                Provide the{" "}
                <span style={{ color: "#FF3B58", fontWeight: "900" }}>
                  {" "}
                  business ID{" "}
                </span>
              </h1>

              <p
                style={{ textAlign: "start", color: "#9B9DAF" }}
                className="para_of_slideone"
              >
                The business ID is the unique number that identifies your
                Gallery as a registered business in your country. This number
                may be referred to as tax ID, company registration number, or
                another equivalent term. <br />
                <br /> We require this information to ensure the authenticity,
                veracity, and security of all Galleries joining Suarte.
              </p>
              <input
                type="text"
                placeholder="Business ID"
                label="fullWidth"
                className="css-input"
                value={formState.gallery_business_id}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    gallery_business_id: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
              sm={12}
              style={{ textAlign: "center" }}
            >
              <h1 className="head_of_slidetwo">
                {" "}
                About
                <span style={{ color: "#FF3B58", fontWeight: "900" }}>
                  {" "}
                  you{" "}
                </span>
              </h1>

              <p
                style={{ textAlign: "start", color: "#9B9DAF" }}
                className="para_of_slideone"
              >
                Provide the Gallery representative’s contact information to
                facilitate direct communication between Suarte and the Gallery
                if necessary.
              </p>

              <input
                type="text"
                placeholder="Full name"
                label="fullWidth"
                className="css-input"
                value={formState.gallery_agent.agent_name}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    gallery_agent: {
                      ...formState.gallery_agent,
                      agent_name: e.target.value,
                    },
                  })
                }
              />
              <br />
              <br />
              <input
                type="text"
                placeholder="Position in the company"
                label="fullWidth"
                className="css-input"
                value={formState.gallery_agent.agent_position}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    gallery_agent: {
                      ...formState.gallery_agent,
                      agent_position: e.target.value,
                    },
                  })
                }
              />
              <br />
              <br />
              <div className="gender_drop">
                <div className="select">
                  <select
                    value={formState.gallery_agent.agent_gender}
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        gallery_agent: {
                          ...formState.gallery_agent,
                          agent_gender: e.target.value,
                        },
                      })
                    }
                  >
                    <option value="">Gender</option>
                    <option>Female</option>
                    <option>Male</option>
                    <option>Other</option>
                    <option>Prefer not to say</option>
                  </select>
                  <div className="select_arrow"></div>
                </div>
                &nbsp; &nbsp; &nbsp;
                <input
                  type="date"
                  id="birthday"
                  name="birthday"
                  placeholder="Date of birth"
                  label="fullWidth"
                  className="css-input_Phone_number"
                  style={{ width: "100%", height: "42px" }}
                  value={formState.gallery_agent.agent_birth}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      gallery_agent: {
                        ...formState.gallery_agent,
                        agent_birth: e.target.value,
                      },
                    })
                  }
                />
              </div>

              <div className="gender_drop">
                <div className="select">
                  <select
                    value={formState.gallery_agent.agent_phone.phone_prefix}
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        gallery_agent: {
                          ...formState.gallery_agent,
                          agent_phone: {
                            ...formState.gallery_agent.agent_phone,
                            phone_prefix: e.target.value,
                          },
                        },
                      })
                    }
                  >
                    <option value="">Prefix</option>
                    {config.prefix_options.map((prefix) => (
                      <option key={prefix.name} value={prefix.dialCode}>
                        {prefix.name}
                      </option>
                    ))}
                  </select>
                  <div className="select_arrow"></div>
                </div>
                &nbsp; &nbsp; &nbsp;
                <input
                  type="text"
                  placeholder="Phone number"
                  label="fullWidth"
                  className="css-input_Phone_number"
                  value={formState.gallery_agent.agent_phone.phone_number}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      gallery_agent: {
                        ...formState.gallery_agent,
                        agent_phone: {
                          ...formState.gallery_agent.agent_phone,
                          phone_number: e.target.value,
                        },
                      },
                    })
                  }
                />
              </div>

              <button
                href="#"
                className="myButton_head_Desktop_Choose_pink"
                onClick={handleNext}
              >
                Next
              </button>
              <br />
              <br />

              <br />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Slideone;
