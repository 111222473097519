import React from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Slideone({ onNext }) {
  const navigate = useNavigate();

  // const handleNext = () => {
  //   onNext();
  // };
  // const handlePrev = () => {
  //   onPrev();
  // };
  return (
    <div className="backofslideonee">
      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Grid container spacing={1}>
          <Grid item md={6} lg={6} xs={12} sm={12}></Grid>
          <Grid
            item
            md={6}
            lg={6}
            xs={12}
            sm={12}
            style={{ textAlign: "center" }}
          >
            <br />
            <br />
            <h1 className="head_of_slidetwo">
              {" "}
              Do you own or represent <br />
              an
              <span style={{ color: "#FFBA00", fontWeight: "900" }}>
                {" "}
                Art Gallery
              </span>{" "}
              ?
            </h1>
            <br />
            <br />
            <br />
            <button
              href="#"
              className="myButton_head_Desktop_no"
              onClick={() => navigate("/")}
            >
              No
            </button>{" "}
            &nbsp; &nbsp; &nbsp;
            <button
              href="#"
              className="myButton_head_Desktop_yes"
              onClick={onNext}
            >
              Yes , next
            </button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Slideone;
