import Loader from './components/Loader';
import XIcon from "../../icons/XIcon";
import PopupToBottomSheet from '../components/PopupToBottomSheet';

import React, { Suspense } from 'react';

import './index.css';

const Content = React.lazy(() => import('./components/Content'));

function ViewInRoomContent({currentSlide, close}) {
    return (
        <>
            <div className="view-in-room__container">
                <div className="view-in-room__header">
                    <span className="view-in-room__header-text">
                        View in room
                    </span>

                    <XIcon className="view-in-room__close" onClick={close}/>
                </div>

                <Suspense fallback={<Loader/>}>
                    <Content index={currentSlide}/>
                </Suspense>
            </div>
        </>
    );
}

export default function ViewInRoom({open, close, currentSlide}) {
    return (
        <>
            <PopupToBottomSheet 
                open={open} 
                close={close}
                className="view-in-room"
                popupOptions={{blur: true}}
            >
                <ViewInRoomContent currentSlide={currentSlide} close={close}/>
            </PopupToBottomSheet>
        </>
    );
};

