import React from "react";
import { useMediaQuery } from "@mui/material";

import Desktop from "../Component/404/Desktop";
import Mobile from "../Component/404/Mobile";

const LandingPage = () => {
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  return (
    <div>
      {" "}
      {!smallScreen && (
        <div className="mainContentfof">
          <div className="pageConfof">
            <Desktop />
          </div>
        </div>
      )}
      {/* This is used For the mobile view Blue print Map*/}
      {smallScreen && (
        <div className="mainContentmobfof">
          <div className="pageConmobfof">
            <Mobile />
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
