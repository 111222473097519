import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { gsap } from "gsap";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import SwiperCore, { Pagination, Navigation } from "swiper/core";
import { Grid } from "@mui/material";
import Slidetwo from "./Slideone";
import Slideone from "./Slidetwo";
import Slidethree from "./Slidethree";
import Slidefour from "./slidefour";
import Slidefive from "./Slidefive";
import Slidesix from "./Slidesix";
import Slideseven from "./Slideseven";
import Slideeight from "./Slideeight";
import "./slide.css";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

SwiperCore.use([Pagination, Navigation]);
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

export default function App() {
  const swiperRef = useRef(null);
  const [initialSlideIndex, setInitialSlideIndex] = useState(1);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const [formState, setFormState] = useState({
    user_name: "",
    user_username: "",
    user_password: "",
    gallery_business_id: "",
    gallery_agent: {
      agent_name: "",
      agent_position: "",
      agent_gender: "",
      agent_birth: "",
      agent_phone: {
        phone_prefix: "",
        phone_number: "",
      },
    },
    gallery_address: {
      street: "",
      city: "",
      zip_code: "",
      region: "",
      country: "",
    },
    user_email: "",
  });

  const handleNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const currentSlideIndex = swiperRef.current.swiper.realIndex;
      let speed = 2000; // Default speed for all other slides

      if (currentSlideIndex === 0 && currentSlideIndex + 1 === 1) {
        speed = 500; // Speed for Slider One to Slider Two
      }
      // Use GSAP to animate the next slide's opacity
      gsap.to(currentSlideIndex, {
        opacity: 1,
        duration: 0.5,
        ease: "bounce.out",
      });

      swiperRef.current.swiper.slideNext(speed);
    }
  };

  const handlePrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const getProgressBarColor = (index) => {
    switch (index) {
      case 0:
        return "red";
      case 1:
        return "blue";
      case 2:
        return "green";
      default:
        return "red";
    }
  };

  return (
    <>
    <div className="progress-bar">
      <div className="progress-bar-inner">
        {/* Create progress dots for each slide */}
        {[0, 1, 2, 3, 4, 5, 6].map((index) => (
          <div
            key={index}
            className={`progress-dot ${
              index <= initialSlideIndex ? "active" : ""
            }`}
          ></div>
        ))}
      </div>
    </div>
      <Swiper
        initialSlide={initialSlideIndex} // Set the initial slide index
        onSlideChange={(swiper) => setInitialSlideIndex(swiper.realIndex)} // Update the initial slide index when changed
        pagination={{
          type: "progressbar",
          renderProgressbar(progressbarFillClass, progressbarWidth) {
            const slideIndex = this.realIndex;
            const color = getProgressBarColor(slideIndex);
            const progressBarStyle = `background-color: ${color}; width: ${progressbarWidth}%;`;
            return `<span className="${progressbarFillClass}" style="${progressBarStyle}"></span>`;
          },
        }}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        className="mySwiper swipe"
        ref={swiperRef}
        allowTouchMove={false}
        spaceBetween={0} // Adjust the space between slides
      >
        <SwiperSlide>
          <div id="slide-0" className="progressbar-container">
            <div className="progressbar" />
            <div className="">
              <Grid container spacing={2}>
                <Grid item md={6} lg={6} xs={6} sm={6}>
                  <button
                    onClick={handleNextSlide}
                    style={{
                      color: "#fff",
                      position: "relative",
                      left: "20px",
                      top: "10px",
                      fontWeight: "700",
                      cursor: "pointer",
                      zIndex: "100",
                      background: "transparent",
                      border: "none",
                    }}
                  >
                    Back
                  </button>
                </Grid>
                <Grid item md={6} lg={6} xs={6} sm={6}>
                  <button
                    onClick={handleOpen}
                    style={{
                      color: "#fff",
                      position: "relative",
                      left: "-20px",
                      top: "10px",
                      fontWeight: "900",
                      cursor: "pointer",
                      zIndex: "100",
                      textAlign: "end",
                      justifyContent: "end",
                      display: "flex",
                      background: "transparent", // Add this line
                      border: "none",
                    }}
                  >
                    <AiOutlineClose style={{ fontWeight: "900" }} />
                  </button>
                </Grid>
              </Grid>
            </div>
          </div>

          <Slidetwo onNext={handleNextSlide} onPrev={handlePrevSlide} />
        </SwiperSlide>

        <SwiperSlide>
          <div id="slide-0" className="">
            <div className="" />
            <div>
              <div className="">
                <Grid container spacing={2}>
                  <Grid item md={6} lg={6} xs={6} sm={6}>
                    <button
                      onClick={() => navigate("/")}
                      style={{
                        color: "#fff",
                        position: "relative",
                        left: "20px",
                        top: "10px",
                        fontWeight: "700",
                        cursor: "pointer",
                        zIndex: "100",
                        background: "transparent",
                        border: "none",
                      }}
                    >
                      Back
                    </button>
                  </Grid>
                  <Grid item md={6} lg={6} xs={6} sm={6}>
                    <button
                      onClick={handleOpen}
                      style={{
                        color: "#fff",
                        position: "relative",
                        left: "-20px",
                        top: "10px",
                        fontWeight: "900",
                        cursor: "pointer",
                        zIndex: "100",
                        textAlign: "end",
                        justifyContent: "end",
                        display: "flex",
                        background: "transparent", // Add this line
                        border: "none",
                      }}
                    >
                      <AiOutlineClose style={{ fontWeight: "900" }} />
                    </button>
                  </Grid>
                </Grid>
              </div>
              <Slideone onNext={handleNextSlide} onPrevv={handlePrevSlide} />
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div>
            <div className="">
              <Grid container spacing={2}>
                <Grid item md={6} lg={6} xs={6} sm={6}>
                  <button
                    onClick={handlePrevSlide}
                    style={{
                      color: "#fff",
                      position: "relative",
                      left: "20px",
                      top: "10px",
                      fontWeight: "700",
                      cursor: "pointer",
                      zIndex: "100",
                      background: "transparent",
                      border: "none",
                    }}
                  >
                    Back
                  </button>
                </Grid>
                <Grid item md={6} lg={6} xs={6} sm={6}>
                  <button
                    onClick={handleOpen}
                    style={{
                      color: "#fff",
                      position: "relative",
                      left: "-20px",
                      top: "10px",
                      fontWeight: "900",
                      cursor: "pointer",
                      zIndex: "100",
                      textAlign: "end",
                      justifyContent: "end",
                      display: "flex",
                      background: "transparent", // Add this line
                      border: "none",
                    }}
                  >
                    <AiOutlineClose style={{ fontWeight: "900" }} />
                  </button>
                </Grid>
              </Grid>
            </div>
            <Slidethree
              onNext={handleNextSlide}
              formState={formState}
              setFormState={setFormState}
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div>
            <div className="">
              <Grid container spacing={2}>
                <Grid item md={6} lg={6} xs={6} sm={6}>
                  <button
                    onClick={handlePrevSlide}
                    style={{
                      color: "#fff",
                      position: "relative",
                      left: "20px",
                      top: "10px",
                      fontWeight: "700",
                      cursor: "pointer",
                      zIndex: "100",
                      background: "transparent",
                      border: "none",
                    }}
                  >
                    Back
                  </button>
                </Grid>
                <Grid item md={6} lg={6} xs={6} sm={6}>
                  <button
                    onClick={handleOpen}
                    style={{
                      color: "#fff",
                      position: "relative",
                      left: "-20px",
                      top: "10px",
                      fontWeight: "900",
                      cursor: "pointer",
                      zIndex: "100",
                      textAlign: "end",
                      justifyContent: "end",
                      display: "flex",
                      background: "transparent", // Add this line
                      border: "none",
                    }}
                  >
                    <AiOutlineClose style={{ fontWeight: "900" }} />
                  </button>
                </Grid>
              </Grid>
            </div>
            <Slidefour
              onNext={handleNextSlide}
              formState={formState}
              setFormState={setFormState}
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div>
            <div className="">
              <Grid container spacing={2}>
                <Grid item md={6} lg={6} xs={6} sm={6}>
                  <button
                    onClick={handlePrevSlide}
                    style={{
                      color: "#fff",
                      position: "relative",
                      left: "20px",
                      top: "10px",
                      fontWeight: "700",
                      cursor: "pointer",
                      zIndex: "100",
                      background: "transparent",
                      border: "none",
                    }}
                  >
                    Back
                  </button>
                </Grid>
                <Grid item md={6} lg={6} xs={6} sm={6}>
                  <button
                    onClick={handleOpen}
                    style={{
                      color: "#fff",
                      position: "relative",
                      left: "-20px",
                      top: "10px",
                      fontWeight: "900",
                      cursor: "pointer",
                      zIndex: "100",
                      textAlign: "end",
                      justifyContent: "end",
                      display: "flex",
                      background: "transparent", // Add this line
                      border: "none",
                    }}
                  >
                    <AiOutlineClose style={{ fontWeight: "900" }} />
                  </button>
                </Grid>
              </Grid>
            </div>
            <Slidefive
              onNext={handleNextSlide}
              formState={formState}
              setFormState={setFormState}
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div>
            <div className="">
              <Grid container spacing={2}>
                <Grid item md={6} lg={6} xs={6} sm={6}>
                  <button
                    onClick={handlePrevSlide}
                    style={{
                      color: "#fff",
                      position: "relative",
                      left: "20px",
                      top: "10px",
                      fontWeight: "700",
                      cursor: "pointer",
                      zIndex: "100",
                      background: "transparent",
                      border: "none",
                    }}
                  >
                    Back
                  </button>
                </Grid>
                <Grid item md={6} lg={6} xs={6} sm={6}>
                  <button
                    onClick={handleOpen}
                    style={{
                      color: "#fff",
                      position: "relative",
                      left: "-20px",
                      top: "10px",
                      fontWeight: "900",
                      cursor: "pointer",
                      zIndex: "100",
                      textAlign: "end",
                      justifyContent: "end",
                      display: "flex",
                      background: "transparent", // Add this line
                      border: "none",
                    }}
                  >
                    <AiOutlineClose style={{ fontWeight: "900" }} />
                  </button>
                </Grid>
              </Grid>
            </div>
            <Slidesix
              onNext={handleNextSlide}
              handleOpen={handleOpen}
              formState={formState}
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div>
            <div className="">
              <Grid container spacing={2}>
                <Grid item md={6} lg={6} xs={6} sm={6}>
                  <button
                    onClick={handlePrevSlide}
                    style={{
                      color: "#fff",
                      position: "relative",
                      left: "20px",
                      top: "10px",
                      fontWeight: "700",
                      cursor: "pointer",
                      zIndex: "100",
                      background: "transparent",
                      border: "none",
                    }}
                  >
                    Back
                  </button>
                </Grid>
                <Grid item md={6} lg={6} xs={6} sm={6}>
                  <button
                    onClick={handleOpen}
                    style={{
                      color: "#fff",
                      position: "relative",
                      left: "-20px",
                      top: "10px",
                      fontWeight: "900",
                      cursor: "pointer",
                      zIndex: "100",
                      textAlign: "end",
                      justifyContent: "end",
                      display: "flex",
                      background: "transparent", // Add this line
                      border: "none",
                    }}
                  >
                    <AiOutlineClose style={{ fontWeight: "900" }} />
                  </button>
                </Grid>
              </Grid>
            </div>
            <Slideseven
              onNext={handleNextSlide}
              handleOpen={handleOpen}
              formState={formState}
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div id="slide-0" className="progressbar-container">
            <div className="progressbar" />
            <div className="">
              <Grid container spacing={2}>
                <Grid item md={6} lg={6} xs={6} sm={6}>
                  <button
                    onClick={handlePrevSlide}
                    style={{
                      color: "#fff",
                      position: "relative",
                      left: "20px",
                      top: "10px",
                      fontWeight: "700",
                      cursor: "pointer",
                      zIndex: "100",
                      background: "transparent",
                      border: "none",
                    }}
                  >
                    Back
                  </button>
                </Grid>
                <Grid item md={6} lg={6} xs={6} sm={6}>
                  <button
                    onClick={handleOpen}
                    style={{
                      color: "#fff",
                      position: "relative",
                      left: "-20px",
                      top: "10px",
                      fontWeight: "900",
                      cursor: "pointer",
                      zIndex: "100",
                      textAlign: "end",
                      justifyContent: "end",
                      display: "flex",
                      background: "transparent", // Add this line
                      border: "none",
                    }}
                  >
                    <AiOutlineClose style={{ fontWeight: "900" }} />
                  </button>
                </Grid>
              </Grid>
            </div>
            <Slideeight
              onNext={handleNextSlide}
              formState={formState}
              setFormState={setFormState}
            />
          </div>
        </SwiperSlide>
      </Swiper>

      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal_bg"
        >
          <Box sx={style} className="modals_bg">
            <h1 className="head_of_slidetwom">
              {" "}
              Are you sure you want to
              <span style={{ color: "#FFB500" }}> exit? </span>
            </h1>
            <p
              style={{ textAlign: "start", color: "#9B9DAF" }}
              className="para_of_slideonem"
            >
              Rememer your progress will be lost and changes will not be saved.
            </p>

            <Grid
              item
              md={6}
              lg={6}
              xs={12}
              sm={12}
              style={{
                textAlign: "center",
                position: "relative",
              }}
            >
              <button
                href="#"
                className="myButton_head_Desktop_Choose_pink_zancgg"
                onClick={handleClose}
              >
                Continue
              </button>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a href="/" className="myButton_head_Desktop_Choose_pink_zanckm1">
                Exit
              </a>
            </Grid>
          </Box>
        </Modal>
      </div>
    </>
  );
}
