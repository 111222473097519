import React from "react";
import { Grid } from "@mui/material";

import fetchWrapper from "../../fetchWrapper";
import { useState } from "react";
import API_URL from "../../config/api-config";

function Slideone({ onNext, formState, setFormState }) {
  const [loading, setLoading] = useState(false);

  const handleNext = async () => {
    if (loading) return;
    if (
      !formState.user_name ||
      !formState.user_username ||
      !formState.user_password
    ) {
      alert("All fields are required!");
      return;
    }

    setLoading(true);

    const { response } = await fetchWrapper.post(
      `${API_URL}/username/available`,
      {
        body: {
          username: formState.user_username,
          password: formState.user_password,
        },
      }
    );

    if (response.ok) {
      onNext();
      setLoading(false);
    } else if ([400, 409].includes(response.status)) {
      setLoading(false);
      alert(
        "Username or password are either invalid or already taken. \n\nA valid username should consist of: \n-Between 3 and 20 characters, at least one letter.\n-Only letters, numbers and underscores are allowed and you can only enter one underscore in a row.\n\nA valid password should consist of: \n-Between 8 and 64 characters.\n-At least one uppercase, one lowercase and one number."
      );
    }
  };

  return (
    <div className="backofslidetwo">
      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <Grid container spacing={1}>
          <Grid
            item
            md={12}
            lg={12}
            xs={12}
            sm={12}
            style={{ textAlign: "center" }}
          >
            <br />
            <br />
            <h1 className="head_of_slidetwo">
              {" "}
              What’s the{" "}
              <span style={{ color: "#FF7900", fontWeight: "900" }}>
                {" "}
                name{" "}
              </span>{" "}
              of the Gallery?
            </h1>
            <br />
            <br />
            <input
              type="text"
              placeholder=""
              label="fullWidth"
              className="css-inputs"
              value={formState.user_name}
              onChange={(e) =>
                setFormState({ ...formState, user_name: e.target.value })
              }
            />
            <br />
            <br />
            <br />
            <h1 className="head_of_slidetwo">
              {" "}
              Choose a{" "}
              <span style={{ color: "#FF7900", fontWeight: "900" }}>
                {" "}
                username{" "}
              </span>
            </h1>
            <br />
            <br />
            <input
              type="text"
              placeholder="@"
              label="fullWidth"
              className="css-inputs"
              value={formState.user_username}
              onChange={(e) =>
                setFormState({ ...formState, user_username: e.target.value })
              }
            />
            <br />
            <br />
            <br />
            <h1 className="head_of_slidetwo">
              {" "}
              Create a{" "}
              <span style={{ color: "#FF7900", fontWeight: "900" }}>
                {" "}
                password{" "}
              </span>
            </h1>
            <br />
            <br />
            <input
              type="password"
              placeholder=""
              label="fullWidth"
              className="css-inputs"
              value={formState.user_password}
              onChange={(e) =>
                setFormState({ ...formState, user_password: e.target.value })
              }
            />
            <br />
            <br />
            <button
              href="#"
              className="myButton_head_Desktop_Choose"
              onClick={handleNext}
            >
              {loading ? "Checking username and password..." : "Next"}
            </button>
            <br />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Slideone;
