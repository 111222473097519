import './styles/Loader.css';

export default function Loader() {
    return (
        <>
            <p style={{
                color: "white",
                fontSize: "14px",
                textAlign: "center",
                marginTop: "20px"
            }}>
                Loading experience...
            </p>
        </>
    );
};

