import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
    <App />,
  document.getElementById("root")
);
