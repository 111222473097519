import React from "react";
import { useMediaQuery } from "@mui/material";

import NavbarDesktop from "../Component/Navbar/Navbar";
import NavbarMobile from "../Component/Navbar/NavbarMobile";

import HeroSectionDesktop from "../Component/HeroSection/HeroSectionDesktop";
import HeroSectionMobile from "../Component/HeroSection/HeroSectionMobile";

import UnderHeroCard from "../Component/UnderHero/UnderHeroCards";

import SuarteWorldDesktop from "../Component/SuarteWorld/SuarteWorldDesktop";
import SuarteWorldMobile from "../Component/SuarteWorld/SuarteWorldMobile";

import UnderSuartaCard from "../Component/UnderSuarta/UnderHeroCards";

import DesktopViewinRoom from "../Component/ViewInRoom/DesktopViewinRoom";
import MobileViewinRoom from "../Component/ViewInRoom/MobileViewinRoom";

import DesktopAbout from "../Component/About/DesktopAbout";
import MobileAbout from "../Component/About/MobileAbout";

import DesktopLearn from "../Component/LEARNTHEESSENTIALS/Desktop_LEARNTHEESSENTIALS";
import MobileLearn from "../Component/LEARNTHEESSENTIALS/Mobile_LEARNTHEESSENTIALS";

import FooterDesktop from "../Component/Footer/FooterDesktop";
import FooterMobile from "../Component/Footer/FooterMobile";

import { useEffect } from "react";

import fetchWrapper from "../fetchWrapper";
import API_URL from "../config/api-config";

const LandingPage = () => {
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  useEffect(() => {
    fetchWrapper.post(`${API_URL}/landingtracking`, {
      body: {
        full_url: window.location.href,
      },
    });
  }, []);

  function navigateToEssentials() {
    const essentialsElement = document.getElementById("essentials");
    essentialsElement.scrollIntoView({
      behavior: "smooth",
    });
  }

  return (
    <div>
      {" "}
      {!smallScreen && (
        <div className="mainContent">
          <div className="pageCon">
            <NavbarDesktop navigateToEssentials={navigateToEssentials} />
            <HeroSectionDesktop />
            <br />
            <br />
            <UnderHeroCard />
            <br />
            <br />
            <SuarteWorldDesktop />
            <br />
            <br />
            <UnderSuartaCard />
            <DesktopViewinRoom />
            <br />
            <br />
            <br />
            <DesktopAbout />
            <br />
            <br />
            <br />
            <DesktopLearn />
            <br />
            <br />
            <br />
            <FooterDesktop />{" "}
          </div>
        </div>
      )}
      {/* This is used For the mobile view Blue print Map*/}
      {smallScreen && (
        <div className="mainContentmob">
          <div className="pageConmob">
            <NavbarMobile navigateToEssentials={navigateToEssentials} />
            <HeroSectionMobile />
            <br />
            <br />
            <UnderHeroCard />
            <br />
            <SuarteWorldMobile />

            <UnderSuartaCard />
            <MobileViewinRoom />
            <br />
            <br />
            <MobileAbout />
            <br />
            <MobileLearn />
            <br />
            <FooterMobile />
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
