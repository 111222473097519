import React from "react";
import { Grid } from "@mui/material";
import fetchWrapper from "../../fetchWrapper";

import { useState } from "react";

import { useNavigate } from "react-router-dom";
import API_URL from "../../config/api-config";

function Slideone() {
  const [notifyState, setNotifyState] = useState("");
  const [checkboxAccepted, setCheckboxAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function submitNotify() {
    if (loading) return;
    if (!notifyState) {
      alert("All fields are required!");
      return;
    }
    if (!checkboxAccepted) {
      alert("You must accept the checkbox");
      return;
    }

    setLoading(true);

    const { response } = await fetchWrapper.post(`${API_URL}/emaillist`, {
      body: {
        email: notifyState,
      },
    });

    if (response.ok) {
      alert("Thanks for joining! You will get notified for the launch!");
      navigate("/");
    } else {
      alert("Something went wrong. The email provided might be invalid.");
      setNotifyState("");
      setLoading(false);
    }
  }

  return (
    <div className="mobbackofslideone">
      <div>
        <br />
        <br />
        <Grid container spacing={1}>
          <Grid item md={6} lg={6} xs={12} sm={12}></Grid>
          <Grid
            item
            md={6}
            lg={6}
            xs={12}
            sm={12}
            style={{ textAlign: "center" }}
          >
            <h1 className="head_of_slideone">
              {" "}
              Not an{" "}
              <span style={{ color: "#FFBA00", fontWeight: "900" }}>
                {" "}
                Art Gallery
              </span>{" "}
              ?
            </h1>
            <br />
            <p className="para_of_slideone">
              Enter your email address below to get notified when the app is
              officially launched.
            </p>

            <input
              type="text"
              placeholder="Enter email..."
              label="fullWidth"
              className="css-input"
              value={notifyState}
              onChange={(e) => setNotifyState(e.target.value)}
            />
            <br />
            <br />
            <div className="move_check_field">
              {" "}
              <label className="b-contain">
                <span style={{ color: "#9B9DAF " }}>
                  I agree to receive emails from Suarte
                </span>
                <input
                  type="checkbox"
                  checked={checkboxAccepted}
                  onChange={(e) => setCheckboxAccepted(e.target.checked)}
                />
                <div className="b-input"></div>
              </label>
            </div>

            <button className="myButton_head_Mobilee" onClick={submitNotify}>
              {loading ? "Loading..." : "Get early access"}
            </button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Slideone;
