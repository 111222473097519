function request(method) {
    return async (url, options) => {
        let fetchOptions = { method };
        
        if(options?.headers) {
            fetchOptions = {...fetchOptions, headers: {...fetchOptions.headers, ...options.headers}}
        }

        //CONTENT HANDLER (BODY AND HEADERS)
        if(!(options?.dataType)) {
            fetchOptions = {...fetchOptions, headers: {...fetchOptions.headers, 'Content-Type': 'application/json', 'Accept': '*/*'}, body: JSON.stringify(options?.body)}
        } else if(options?.dataType === 'default') { //Inject everything as it is
            fetchOptions = {...fetchOptions, body: options?.body}
        }

        return new Promise((resolve, reject) => {
            fetch(url, fetchOptions)
                .then(response => {
                    response.json()
                    .then(data => {
                        resolve({response, data})
                    })
                })
                .catch(error => reject(error));
        })
    }
}

const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
}

export default fetchWrapper; 
