import React from "react";
import { BsInstagram, BsLinkedin } from "react-icons/bs";
import { Grid } from "@mui/material";

function FooterDesktop() {
  // async function submitNotify() {
  //   if (loading) return;
  //   if (!notifyState) {
  //     alert("All fields are required!");
  //     return;
  //   }

  //   setLoading(true);

  //   const { response } = await fetchWrapper.post(`${API_URL}/emaillist`, {
  //     body: {
  //       email: notifyState,
  //     },
  //   });

  //   if (response.ok) {
  //     alert("Thanks for joining! You will get notified for the launch!");
  //     setNotifyState("");
  //     setLoading(false);
  //   } else {
  //     alert("Something went wrong. The email provided might be invalid.");
  //     setNotifyState("");
  //     setLoading(false);
  //   }
  // }

  return (
    <div>
      {" "}
      <div className="desktop_footer_bg">
        {/* <div className="container center_hero_logo">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          <br />
          <br />
          <br />
          <br />
          <br />
          <h5 className="head_footer">Want to get notified for the launch?</h5>
          <br />

          <br />
          <input
            className="css-input-mobs"
            type="email"
            id="Enter email..."
            name="Enter email..."
            placeholder="Enter email..."
            value={notifyState}
            onChange={e => setNotifyState(e.target.value)}
          />
          <br />
          <br />
          <a className="myButton_head_Desktop_Join_Notify" onClick={submitNotify}>
            {
              loading ? 
                "Loading..."
              :
                "Notify me"
            }
          </a>
        </div> */}
      </div>
      <div className="footer_last">
        <Grid container spacing={3}>
          <Grid item md={4} lg={4} xs={12} sm={12}>
            <a href="tel:+34628171410" className="last_footer_left_text">
              Telf: +34 628 171 410
            </a>
            <br />
            <br />
            <a
              href="mailto:contact@suarte.art"
              className="last_footer_left_text"
            >
              E-mail: contact@suarte.art
            </a>
          </Grid>
          <Grid
            item
            md={4}
            lg={4}
            xs={12}
            sm={12}
            style={{ textAlign: "center" }}
          >
            <a
              href="https://suarte.art/privacy-policy"
              className="last_footer_left_text"
            >
              Privacy policy
            </a>
            <a
              href="https://suarte.art/terms"
              className="last_footer_left_text"
            >
              Terms
            </a>
            <a
              href="https://suarte.art/cookie-policy"
              className="last_footer_left_text"
            >
              Cookie policy
            </a>
            <br />
            <br />
          </Grid>
          <Grid item md={4} lg={4} xs={12} sm={12} style={{ textAlign: "end" }}>
            <a
              href="https://www.instagram.com/wearesuarte/"
              className="last_footer_left_text"
            >
              @wearesuarte <BsInstagram />
            </a>
            <br />
            <br />
            <a
              href="https://www.linkedin.com/company/suartesl/"
              className="last_footer_left_text"
            >
              Get in touch with us <BsLinkedin />
            </a>
          </Grid>
        </Grid>
        <br />
      </div>
    </div>
  );
}

export default FooterDesktop;
