import React from "react";
import { Grid } from "@mui/material";

import config from "../../config";

function Slideone({ onNext, formState, setFormState }) {
  const handleNext = () => {
    if (
      !formState.gallery_address.street ||
      !formState.gallery_address.city ||
      !formState.gallery_address.zip_code ||
      !formState.gallery_address.region ||
      !formState.gallery_address.country
    ) {
      alert("All fields are required!");
      return;
    }

    onNext();
  };

  return (
    <div className="mobbackofslidefour">
      <div>
        <br />
        <br />
        <br />
        <div className="container">
          <Grid container spacing={10}>
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
              sm={12}
              style={{ textAlign: "center" }}
            >
              <h1 className="head_of_slidetwo">
                {" "}
                <span style={{ color: "#FF0097", fontWeight: "900" }}>
                  Where
                </span>{" "}
                is {formState.user_name}?
              </h1>

              <p
                style={{ textAlign: "center", color: "#9B9DAF" }}
                className="para_of_slideone"
              >
                Complete the following fields to let us know where the Gallery
                is located.
              </p>
              <br />
              <input
                type="text"
                placeholder="Address"
                label="fullWidth"
                className="css-input"
                value={formState.gallery_address.street}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    gallery_address: {
                      ...formState.gallery_address,
                      street: e.target.value,
                    },
                  })
                }
              />

              <br />
              <br />

              <div className="gender_drop">
                <input
                  type="text"
                  placeholder="City"
                  label="fullWidth"
                  className="css-input_Phone_number"
                  value={formState.gallery_address.city}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      gallery_address: {
                        ...formState.gallery_address,
                        city: e.target.value,
                      },
                    })
                  }
                />
                &nbsp; &nbsp; &nbsp;
                <input
                  type="text"
                  placeholder="Zip code"
                  label="fullWidth"
                  className="css-input_Phone_number"
                  value={formState.gallery_address.zip_code}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      gallery_address: {
                        ...formState.gallery_address,
                        zip_code: e.target.value,
                      },
                    })
                  }
                />
              </div>

              <div className="gender_drop">
                <input
                  type="text"
                  placeholder="Province/Region"
                  label="fullWidth"
                  className="css-input_Phone_number"
                  style={{ width: "50%" }}
                  value={formState.gallery_address.region}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      gallery_address: {
                        ...formState.gallery_address,
                        region: e.target.value,
                      },
                    })
                  }
                />
                &nbsp; &nbsp;
                <div className="select">
                  <select
                    style={{ height: "44px", marginLeft: "-5px" }}
                    value={formState.gallery_address.country}
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        gallery_address: {
                          ...formState.gallery_address,
                          country: e.target.value,
                        },
                      })
                    }
                  >
                    <option value="">Country</option>
                    {config.prefix_options.map((prefix) => (
                      <option key={prefix.name} value={prefix.name}>
                        {prefix.name}
                      </option>
                    ))}
                  </select>
                  <div className="select_arrow"></div>
                </div>
              </div>

              <br />

              <button
                href="#"
                className="myButton_head_Desktop_Choose_pink_zann"
                onClick={handleNext}
              >
                Next
              </button>
              <br />
              <br />

              <br />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Slideone;
