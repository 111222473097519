import React from "react";
import { Grid } from "@mui/material";

import fetchWrapper from "../../fetchWrapper";

import { useState } from "react";

import { useNavigate } from "react-router-dom";
import API_URL from "../../config/api-config";

function Slideone({ onNext, formState, setFormState }) {
  const [loading, setLoading] = useState(false);
  const [checkboxAccepted, setCheckboxAccepted] = useState(false);
  const navigate = useNavigate();

  function getYearObjectFromInput(inputValue) {
    let yearObject = null;

    if (inputValue) {
      const inputDate = new Date(inputValue);
      yearObject = {
        day: inputDate.getDate(),
        month: inputDate.getMonth(),
        year: inputDate.getFullYear(),
      };
    }

    return yearObject;
  }

  const handleNext = async () => {
    if (loading) return;
    if (!formState.user_email) {
      alert("Enter your email to continue the request");
      return;
    }
    if (!checkboxAccepted) {
      alert("You must accept the checkbox to continue");
      return;
    }

    setLoading(true);

    const { response, data } = await fetchWrapper.post(
      `${API_URL}/request/access/gallery`,
      {
        body: {
          ...formState,
          gallery_agent: {
            ...formState.gallery_agent,
            agent_birth: getYearObjectFromInput(
              formState.gallery_agent.agent_birth
            ),
          },
        },
      }
    );

    if (response.ok) {
      alert("Your request has been submitted successfully!");
      navigate("/");
      window.lintrk("track", { conversion_id: 14023905 });
    } else if (response.status === 400) {
      setLoading(false);
      alert(`Something went wrong.\nReason: ${data.error_message}`);
    }
  };

  return (
    <div className="mobbackofslideseven">
      <div>
        <br /> <br /> <br />
        <div className="container">
          <Grid container spacing={10}>
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
              sm={12}
              style={{ textAlign: "center" }}
            >
              <h1 className="head_of_slidetwo">
                {" "}
                <span style={{ color: "#FFB500", fontWeight: "900" }}>
                  Last step
                </span>{" "}
                to join Suarte
              </h1>

              <p
                style={{ textAlign: "center", color: "#9B9DAF" }}
                className="para_of_slideone"
              >
                Congratulations! You are now on your way to joining . Please
                submit your Gallery’s email address to complete the form.
              </p>
              {/* <img src="https://imagedelivery.net/hAeIC__6Aj746x0RFU1joA/8f5059aa-765e-4e2f-04d9-cfecb23b1700/w=2000" style={{width :"100%" , height:"unset"}}/> */}
              <br />
              <input
                type="text"
                placeholder="Gallery’s email"
                label="fullWidth"
                className="css-input"
                value={formState.user_email}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    user_email: e.target.value,
                  })
                }
              />
              <br />
              <br />
              <div className="move_check_field">
                {" "}
                <label className="b-contain">
                  <span style={{ color: "#9B9DAF " }}>
                    I agree to receive emails from Suarte
                  </span>
                  <input
                    type="checkbox"
                    checked={checkboxAccepted}
                    onChange={(e) => setCheckboxAccepted(e.target.checked)}
                  />
                  <div className="b-input"></div>
                </label>
              </div>

              <button
                href="#"
                className="myButton_head_Mobilee"
                onClick={handleNext}
              >
                {loading ? "Loading request..." : "Join now"}
              </button>
              <div className="gender_drop"></div>
              <br />
              <br />
              <br />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Slideone;
