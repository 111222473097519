import React from "react";
import { Grid } from "@mui/material";
function Slideone({ onNext, handleOpen, formState }) {
  const handleNext = () => {
    onNext();
  };

  return (
    <div className="backofslidefive">
      <div>
        <br />
        <br />
        <br />
        <br />

        <div className="container">
          <Grid container spacing={10}>
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
              sm={12}
              style={{ textAlign: "center" }}
            >
              <h1 className="head_of_slidetwo">
                {" "}
                Can {formState.user_name} provide{" "}
                <span style={{ color: "#D000C5", fontWeight: "900" }}>
                  quality pictures
                </span>{" "}
                of the artworks?
              </h1>
              <br />
              <p
                style={{ textAlign: "center", color: "#9B9DAF" }}
                className="para_of_slideone"
              >
                Quality photos boost Gallery conversion rates and improve the
                browsing experience for art lovers and collectors. As such, all
                artworks listed for sale on Suarte must meet quality standards.
              </p>
              <button
                href="#"
                className="myButton_head_Desktop_Choose_pink_zancg"
                onClick={handleOpen}
              >
                No, exit
              </button>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <button
                href="#"
                className="myButton_head_Desktop_Choose_pink_zanc"
                onClick={handleNext}
              >
                Yes, next
              </button>
              <div className="gender_drop"></div>
              <br />
              <br />
              <br />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Slideone;
