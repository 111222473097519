import React from "react";
import { Grid } from "@mui/material";
function Desktop() {
  return (
    <div className="container" style={{ textAlign: "center" }}>
      <Grid container>
        <br />

        <Grid item md={12} lg={12} xs={12} sm={12} className="aloo">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="cardz has-bg-img bg-img-nature">
            <span className="">
              <br />
              <br />
              <br />
              <br />
              <h2 className="foftext">
                <span style={{ fontWeight: "600" }}>404</span>
                <br />
                not found
              </h2>

              <br />
              <br />
              <br />
              <a href="./" className="myButton_head_Desktop_fof">
                go home
              </a>
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Desktop;
